.fc-theme-standard .fc-scrollgrid {
    border: none;
  }
  
  /* .fc-scroller {
    padding: 10px 0;
  } */
  
  .fc-timegrid-divider {
    display: none;
  }
  
  .fc-event {
    background-color: #eae8ff;
    color: #333;
    border-radius: 4px;
    padding: 4px;
    font-size: 0.9em;
  }
  .fc-scroller {
    overflow: hidden !important;
  }
  .fc-timegrid-slot-label {
    border: none !important;
  }
  
  .fc-theme-standard td {
    border-bottom: none !important;
    border-right: none !important;
  }
  .fc-theme-standard td,
  .fc-theme-standard th {
    border-right: none !important;
  }
  .fc .fc-button-primary:focus {
    box-shadow: none !important;
  }
  .fc-icon-chevron-left {
    color: black;
    font-size: 22px !important;
  }
  .fc-icon-chevron-right {
    color: black;
    font-size: 22px !important;
  }
  .fc-next-button {
    background-color: var(--custom-grey-100) !important;
    margin-left: 5px !important;
    padding: 0px 5px !important;
    border: none !important;
  }
  .fc-prev-button {
    background-color: var(--custom-grey-100) !important;
    margin-right: 5px !important;
    padding: 0px 5px !important;
    border: none !important;
  }
  .fc-today-button {
    color: black !important;
    background-color: var(--custom-grey-100) !important;
    border: none !important;
    text-transform: capitalize !important;
  }
  
  .fc .fc-button-primary:not(:disabled).fc-button-active {
    background-color: var(--custom-primary) !important;
    border: none !important;
    text-transform: capitalize !important;
    border-radius: 8px !important;
    font-weight: 500 !important;
  }
  .fc .fc-button-primary {
    background-color: transparent;
    color: var(--custom-gray-500);
    border: none;
    text-transform: capitalize !important;
  }
  .fc .fc-button-primary:hover {
    background-color: var(--custom-primary) !important;
    border: none !important;
    border-radius: 8px !important;
    color: white !important;
  }
  .fc .fc-button:disabled {
    opacity: 1 !important;
  }
  .fc-timeGridWeek-button {
    margin-left: 16px !important;
  }
  .fc-dayGridMonth-button {
    margin-left: 16px !important;
  }
  .fc-year-button {
    margin-left: 16px !important;
  }
  .fc .fc-timegrid-col.fc-day-today {
    background-color: #eff6ff;
  }
  .fc-theme-standard td {
    height: 120px !important;
    /* padding: 50px !important; */
  }
  /* .fc-timegrid-col{
      height: 185px !important;
  } */
  .fc-v-event {
    border-left: 4px solid;
    border-color: var(--custom-primary);
    border-right: none;
    border-top: none;
    border-bottom: none;
    color: var(--custom-neutrals-100) !important;
  }
  .fc-timegrid-event-harness {
    margin-top: -15px;
    height: 120px;
    /* width: auto; */
    margin-left: -18px;
  }
  .fc-timegrid-event-harness-inset {
    width: 208px;
  }
  table th:last-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .fc-event-wrapper h6 {
    color: var(--custom-neutrals-100);
    font-weight: 600;
    line-height: 16px;
    font-size: 12px;
  }
  .fc-event-wrapper div {
    color: var(--custom-grey-500);
    font-weight: 500;
    line-height: 160%;
    font-size: 8px;
  }
  .fc-event-wrapper span {
    color: var(--custom-neutrals-100);
    font-size: 10px;
  }
  .fc-event-wrapper p {
    color: var(--custom-neutrals-100);
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
  }
  .fc th {
    text-align: left;
    background-color: transparent !important;
  }
  .fc-setAvailability-button {
    background-color: var(--custom-primary) !important;
    padding: 9px 20px !important;
    color: white !important;
    border-radius: 8px !important;
  }
  

  .fc-timeGridDay-view {
    height: 100vh !important;
    overflow-y: auto !important;
  }


  .calendar-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
  }
  
  .fc-today-button {
    margin-right: 15px;
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .calendar-month-year {
    font-size: 18px;
    font-weight: bold;
    color: #333;
  }
  