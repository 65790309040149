.job-information-wrapper {
  width: 100%;
  background-color: #fff;
  padding: 24px 16px;
  border-radius: 12px;
  border: 2px solid var(--custom-gray-100);
}
.border-wrapper {
  border-bottom: 1px solid var(--custom-grey-200);
}
.back-slide-label {
  color: var(--custom-neutrals-100);
  line-height: 120%;
  font-size: 16px;
  font-weight: 500;
}
.job-name {
  font-size: 24px;
  line-height: 120%;
  font-weight: bold;
  color: black;
}
.status-indicator {
  background-color: var(--custom-success-50);
  padding: 2px 8px;
  border-radius: 15px;
  font-size: 12px;
  line-height: 24px;
  font-weight: 500;
  color: var(--custom-success-alert);
}
.status-indicator-features {
  border-radius: 100%;
  background-color: var(--custom-grey-100);
  height: 23px;
  width: 23px;
}
.candidate-count {
  color: var(--custom-grey-500);
  font-size: 12px;
  line-height: 160%;
  font-weight: 500;
}
.job-details {
  width: 100%;
  background-color: #fff;
  padding: 16px;
  border-block: 1px solid var(--custom-gray-100);
  /* box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.05), 0 2px 4px rgba(0, 0, 0, 0.05); */
}
.state-name {
  font-weight: 500;
  font-size: 12px;
  line-height: 160%;
  color: var(--custom-grey-500);
}
.deadline-commitment {
  font-weight: 500;
  font-size: 12px;
  line-height: 160%;
  color: var(--custom-grey-700);
  background-color: var(--custom-gray-100);
  border-radius: 10px;
  padding: 2px 8px;
  width: fit-content;
  text-transform: uppercase;
}

.deadline-commitment-1 {
  font-weight: 500;
  font-size: 12px;
  line-height: 160%;
  color: var(--custom-grey-700);
  border-radius: 10px;
  padding: 2px 8px;
  width: fit-content;
  text-transform: uppercase;
}
.work-commit-date {
  line-height: auto;
  font-size: 12px;
  font-weight: 500;
  color: var(--custom-grey-500);
}
.rate {
  font-size: 14px;
  font-weight: 600;
  line-height: auto;
  color: var(--custom-grey-800);
}
.rate span {
  color: var(--custom-success-alert);
  font-size: 10px;
  font-weight: 600;
  line-height: 20px;
}
.rate span span {
  color: var(--custom-primary);
  font-size: 10px;
  font-weight: 600;
  line-height: 20px;
}
.platform-and-sales-fees {
  color: var(--custom-grey-500);
  font-size: 12px;
  font-weight: 500;
  line-height: auto;
}

#job-info__dropdown ~ .dropdown-menu {
  min-width: 185px;
}
