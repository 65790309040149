.padding-select-box {
  padding: 10px;
  color: var(--custom-grey-500);
}

.find-work .nav-tabs {
  --bs-nav-tabs-border-color: transparent !important;
}
.find_work_tab_font {
  font-size: 13px !important;
}
