:root {
  --custom-primary: #920af2;
  --custom-primary-alert-50: #f7ecff;
  --custom-primary-50: #ffece5;
  --custom-primary-100: #e7f7ef;
  --custom-primary-400: #f56630;
  --custom-primary-500: #eb5017;
  --custom-warning-50: #fef6e7;
  --custom-warning-500: #dd900d;
  --custom-primary-600: #eff6ff;
  --custom-secondary-50: #e3effc;
  --custom-secondary-200: #80bbff;
  --custom-secondary-500: #0d5eba;
  --custom-accent-purple: #7b61ff;
  --custom-accent-blue: #26a4ff;
  --custom-accent-green: #56cdad;
  --custom-accent-yellow: #ffb836;
  --custom-success-alert: #0caf60;
  --custom-success-50: #ecfdf3;
  --custom-error-alert: #e03137;
  --custom-brand-50: #f9f5ff;
  --custom-brand-600: #7f56d9;
  --custom-brand-700: #6941c6;
  --custom-gray-100: #f2f4f7;
  --custom-gray-300: #d0d5dd;
  --custom-gray-500: #667085;
  --custom-gray-700: #344054;
  --custom-grey-50: #f9fafb;
  --custom-grey-75: #f7f9fc;
  --custom-grey-100: #f0f2f5;
  --custom-grey-200: #e4e7ec;
  --custom-grey-400: #98a2b3;
  --custom-grey-500: #667185;
  --custom-grey-600: #475367;
  --custom-grey-800: #1d2739;
  --custom-grey-900: #101928;
  --custom-grayscale-50: #fafafa;
  --custom-grayscale-100: #f1f5f9;
  --custom-grayscale-200: #f1f2f4;
  --custom-grayscale-500: #a0aec0;
  --custom-grayscale-600: #687588;
  --custom-grayscale-900: #111827;
  --custom-lights-border: #d6ddeb;
  --custom-neutrals-60: #7c8493;
  --custom-neutrals-80: #515b6f;
  --custom-neutrals-100: #25324b;
  --custom-neutrals-200: #dcdce4;
  --custom-ebony: #0f172a;
  --custom-catskill-white: #e2e8f0;
  --custom-selago: #f3f3fe;
  --custom-dark-shade-purple: #150a33;
  --custom-brand-secondary: #ccccf5;
  --custom-error-color: #dd524d;
  --custom-success-color: #40b869;
  --custom-pending-color: #865503;
  --custom-dark-gray: #84818a;
  --toastify-color-success: #920af2 !important;
}

.color-primary {
  color: var(--custom-primary) !important;
}

.color-primary-500 {
  color: var(--custom-primary-500) !important;
}

.custom-badge {
  background-color: #8656030d !important;
  color: var(--custom-pending-color) !important;
  border-radius: 8px;
}

.color-secondary {
  color: #94a3b8 !important;
}

.color-ebony {
  color: var(--custom-ebony) !important;
}

.color-grey-500 {
  color: var(--custom-grey-500) !important;
}

.color-grey-900 {
  color: var(--custom-grey-900) !important;
}

.color-gray-700 {
  color: var(--custom-gray-700) !important;
}

.color-gray-800 {
  color: var(--custom-grey-800) !important;
}

.color-grayscale-500 {
  color: var(--custom-grayscale-500) !important;
}

.color-grayscale-900 {
  color: var(--custom-grayscale-900) !important;
}

.color-pale-sky {
  color: var(--custom-grayscale-600) !important;
}

.color-black {
  color: black !important;
}

.color-dark-gray {
  color: var(--custom-dark-gray) !important;
}

.color-blue {
  color: #2f3eff !important;
}

.color-error {
  color: #d42620 !important;
}

.color-green {
  color: #27ae60 !important;
}

.color-thunderbird {
  color: #ba1a1a !important;
}

/* bg */
.color-bg-error {
  background-color: #fbeae9 !important;
}

.color-bg-primary {
  background-color: var(--custom-primary) !important;
}

.color-custom-grey-500 {
  color: var(--custom-grey-500);
}

.color-fiord {
  color: var(--custom-grey-600) !important;
}

.border-none {
  border: none !important;
}

.font-36 {
  font-size: 36px !important;
}

.font-28 {
  font-size: 28px !important;
}

.font-22 {
  font-size: 22px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-13 {
  font-size: 13px !important;
}

.font-9 {
  font-size: 9px !important;
}

.font-11 {
  font-size: 11px !important;
}

.font-10 {
  font-size: 10px !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-bold {
  font-weight: bold !important;
}

.fs-inherit {
  font-size: inherit !important;
}

.fw-inherit {
  font-weight: inherit !important;
}

.color-inherit {
  color: inherit !important;
}

.line-height-160 {
  line-height: 160% !important;
}

.line-height-150 {
  line-height: 150% !important;
}

.line-height-100 {
  line-height: 100% !important;
}

.line-height-110 {
  line-height: 110% !important;
}

.line-height-125 {
  line-height: 125% !important;
}

.line-height-130 {
  line-height: 130% !important;
}

.line-height-145 {
  line-height: 145% !important;
}

.line-height-20 {
  line-height: 20px !important;
}

.latter_spacing-1 {
  letter-spacing: -1% !important;
}

.border-botticelli {
  border-color: var(--custom-lights-border) !important;
}

.border-ebony {
  border-color: var(--custom-ebony) !important;
}

.border-grayscale-100 {
  border-color: var(--custom-grayscale-100) !important;
}

.border-solid {
  border-style: solid !important;
}

.bg-grayscale-50 {
  background-color: var(--custom-grayscale-50);
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-grab {
  cursor: grab !important;
}

.w-fit {
  width: fit-content !important;
}

.assigned-avatar {
  height: 30px;
  width: 30px;
  background-color: #ceefdf;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}

a {
  text-decoration: none !important;
}

.star {
  color: var(--custom-error-color);
}

.react-datepicker-wrapper {
  width: 100%;
}

.font-italic {
  font-style: italic;
}

.fit-content {
  width: fit-content;
}

.text-transform-none {
  text-transform: none !important;
}

.future-pending {
  filter: grayscale(0.5) !important;
  cursor: not-allowed;
}
