.providerCount-info {
    color: var(--custom-neutrals-60);
    font-size: 14px;
    font-style: italic
}

.padded-border-button {
    padding-block: 14px;
    border: 1px solid var(--custom-brand-secondary);
}

.description-style {
    color: #84818A;
    font-weight: 500;
}

.calendar-icon {
    top: 58%;
    right: 15px;
}

.py-12px {
    padding-block: 12px !important;
}
.px-12px {
    padding-inline: 12px !important;
}

.py-14px {
    padding-block: 14px !important;
}
.px-14px {
    padding-inline: 14px !important;
}

.location-schedule__dropdown .dropdown-menu {
    width: 100%;
}

.btn-edit__saved-location {
    background-color: var(--custom-grey-75);
    color: var(--custom-grey-500);
}