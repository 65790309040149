.zero_button {
    border-radius: 10px;
    padding: 21px 24px;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.3px;
}

.custom_button {
    background-color: var(--custom-primary);
    color: #FFF;
    border: none;
}

.zero_button:disabled {
    background-color: #E5E1E6;
    color: #FFF;
}

.custom_button-outline {
    border: 1px solid var(--custom-primary);
    background-color: transparent;
    color: var(--custom-primary);
}