.dropzone__instruction {
    color: var(--custom-neutrals-80);
}

.dropzone__accepted-formats {
    color: var(--custom-neutrals-60);
}

.btn-wrapper__docusign {
    background-color: var(--custom-grey-100);
    padding: 12px;
    border: none;
}

.template {
    height: 300px;
}

/* DOCUSIGN Modal */

.docusign__img {
    height: 100px;
    width: 100px;
}

.docusign_modal-header {
    border-bottom: none;
}

.btn__agreement-close {
    position: absolute;
    top: -40px;
    z-index: 1;
    height: 24px;
    width: 24px;
}