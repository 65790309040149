.mask-group {
    border-radius: 16px;
    background-color: var(--custom-primary);
    background-image: url(../../../assets/images/mask-group.png);
    background-size: cover;
}

.action .row:first-child {
    color: var(--custom-grey-100);
}

.action .applicant-summary {
    border-radius: 12px;
    border: 1px solid var(--custom-lights-border);
    color: var(--custom-neutrals-100);
}

.analytics h1 {
    font-size: clamp(1em, 48px, 2em);
    color: white;
    line-height: 125%;
    display: flex;
    align-items: center;
    gap: 8px;
    width: fit-content;
    margin-bottom: 0;
}

.analytics h1 p {
    color: #FFBD5B;
    font-size: clamp(1em, 30px, 2em);
    letter-spacing: 0.2px;
}

.analytics h6 {
    font-size: 18px;
    line-height: 160%;
    margin-bottom: 0;
}

.new-candidates {
    background-color: #0DBBF9;
}

.schedule {
    background-color: var(--custom-grey-600);
}

.in-progress__work-orders {
    background-color: var(--custom-primary);
}

.new-messages {
    background-color: var(--custom-primary-400);
}

.published__work-orders {
    background-color: var(--custom-accent-yellow);
}

.new-candidates,
.schedule.analytics,
.in-progress__work-orders,
.new-messages,
.published__work-orders {
    border-radius: 12px;
    padding: 20px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.applicant-summary .title {
    padding: 24px 24px 16px;
    margin-bottom: 0;
}

.applicant-summary .total-applicants {
    padding: 8px 12px;
    margin-bottom: 0;
}

.total-applicants span {
    color: var(--custom-neutrals-60)
}

.chart {
    border-radius: 0;
}

.invites {
    background-color: var(--custom-accent-purple);
}

.completed {
    background-color: var(--custom-accent-green);
}

.applied {
    background-color: var(--custom-accent-yellow);
}

.approved {
    background-color: #098D2E;
}

.assigned {
    background-color: var(--custom-accent-blue);
}

.paid {
    background-color: #0A3DF2;
}

.hired div {
    width: 20px;
    height: 20px;
    border-radius: 4px;
}

.hired h6 {
    color: var(--custom-neutrals-60);
}

.hired h6 span {
    color: var(--custom-neutrals-100);
}

@media (min-width: 768px) {

    .action .row > .analytics.col-md-5 {
        flex: 0 0 auto;
        width: 48%;
    }

    .action .applicant-summary {
        margin-right: calc(var(--bs-gutter-x) * .5);
    }

    .action .row:first-child {
        margin-left: calc(var(--bs-gutter-x) * .5);
    }
}