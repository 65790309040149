.color-neutrals-100 {
  color: var(--custom-neutrals-100);
}
.color-neutrals-60 {
  color: var(--custom-neutrals-60);
}
.common-card-provider-details {
  background-color: #f8f8fd;
  border-radius: 16px;
}
.badge__group_provider .progress {
  min-width: 265px;
  height: 11px;
  background-color: var(--custom-lights-border);
}
.badge__group_provider .progress .progress-bar {
  background-color: var(--custom-primary);
}
.btn-link {
  text-decoration: none;
}
.provider-skill {
  background-color: var(--custom-grey-75);
  color: var(--custom-primary);
  border-radius: 16px;
  padding: 4px 12px;
  width: fit-content;
}
.completed-jobs {
  padding: 4px 16px;
  background-color: var(--custom-primary-100) !important;
  color: var(--custom-success-alert);
  border-radius: 8px;
}
.in-progress {
  padding: 4px 16px;
  background-color: var(--custom-secondary-50) !important;
  color: var(--custom-secondary-500);
  border-radius: 8px;
}
.about-provider-main-wrapper {
  position: relative;
}
.active-provider {
  position: absolute;
  top: 28px;
  left: 100px;
}
.selector {
  width: 100px !important;
}
.cvv-months-selector {
  width: 210px;
}
.master-card{
  border-radius: 10px !important;
  border: 1px solid var(--custom-catskill-white) !important;
  padding: 6px 10px;
  width: fit-content;
}
.form-wrapper-upload-order{
  position: relative;
  height: 80vh;
}
.button-submit-upload-order {
  position: absolute;
  bottom: -60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.upload-order-dropzone{
  height: 130px !important;
}
.remove-selected-order{
  background-color: var(--custom-primary-50);
  color: var(--custom-error-color);
  border-radius: 8px;
  padding: 3px 8px;
}
.mt-n3 {
  margin-top: -1rem !important; /* -16px */
}