.card-check-input {
  width: 20px;
  height: 20px;
  margin: 0;
  border-radius: 15px;
}
.user-profile img {
  height: 60px;
  width: 60px;
  border-radius: 100%;
}
.user-profile h6 {
  font-size: 14px;
  line-height: 100%;
  font-weight: 800;
  letter-spacing: 0.2px;
  color: var(--custom-ebony);
}
.user-profile p {
  font-size: 12px;
  line-height: 160%;
  color: var(--custom-grey-500);
}
.provider-item-card {
  height: 100%;
  max-height: 50px;
  min-height: 50px;
}
