.description-heading h6 {
  font-size: 16px;
  font-weight: 500;
  line-height: 160%;
}
.description-heading p {
  font-size: 16px;
  line-height: 160%;
  color: var(--custom-grey-600);
  /* width: 1097px; */
}
.skills-info__heading .skill {
  background-color: var(--custom-grey-75);
  padding: 2px 8px;
  border-radius: 15px;
  font-size: 12px;
  line-height: 24px;
  font-weight: 500;
  color: var(--custom-primary);
}
.skills-info__heading h5 {
  background-color: transparent;
  font-size: 16px;
  line-height: 160%;
  font-weight: 500;
  color: var(--custom-grey-900);
}
.contact-info-heading {
  font-size: 16px;
  line-height: 160%;
  font-weight: 500;
  color: black;
}
.contact-info p {
  font-size: 16px;
  line-height: 160%;
  color: var(--custom-grey-600);
}
.client-info-heading {
  font-size: 16px;
  line-height: 160%;
  font-weight: 500;
  color: var(--custom-grey-900);
}
.on-site {
  font-size: 16px;
  line-height: 160%;
  color: var(--custom-grey-600);
}
.heading-wrapper {
  font-size: 18px;
  line-height: 120%;
  color: black;
  font-weight: 500;
}
.business-id {
  background-color: var(--custom-grey-100);
  border-radius: 15px;
  padding: 2px 10px;
  font-size: 12px;
  line-height: 20px;
  color: var(--custom-grey-700);
}
.client-address {
  font-weight: 500;
  font-size: 12px;
  line-height: 160%;
  color: var(--custom-grey-500);
}
.project-info p {
  font-size: 14px;
  line-height: 160%;
  color: var(--custom-grey-600);
}
.project-info p:nth-child(2),
.project-info p:nth-child(3) {
  font-size: 12px;
  color: var(--custom-grey-500);
  line-height: 160%;
}
.no-of-providers {
  font-size: 16px;
  line-height: 160%;
  color: var(--custom-grey-600);
}

.date-and-time p:nth-child(1),
.date-and-time p:nth-child(2) {
  font-size: 12px;
  color: var(--custom-grey-500);
  line-height: 160%;
  background-color: transparent !important;
}
