.dashboard-search-main {
  background-color: white;
  height: 440px;
  width: 624px;
  position: absolute;
  top: 70px;
  box-shadow: 0px 12px 24px 0px #c1c1c1cf;
  border-radius: 2px 2px 10px 10px;
  padding: 10px;
  overflow: hidden !important;
  /* Optional: Makes the scrollbar thinner */
}

.content-tab {
  overflow-y: auto;
  /* For modern browsers */
  scrollbar-color: #cbd5e1 transparent; /* Thumb color | Track color */
  scrollbar-width: thin;
  overflow-y: auto;
  height: 390px;
}
.footer-all-details {
  background-color: white;
  height: 60px;
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  color: var(--custom-primary);
  justify-content: center;
}
.border-common {
  border-bottom: 1px solid #dee2e6;
}
.content-recent-tab {
  overflow-y: auto;
  scrollbar-color: #cbd5e1 transparent;
  scrollbar-width: thin;
  overflow-y: auto;
  height: 390px;
}
