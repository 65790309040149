.tiptap.ProseMirror {
    display: block;
    border-radius: 10px;
    border: 1px solid var(--custom-catskill-white);
    padding: 16px 20px;
    width: 100%;
    height: 130px;
    overflow-y: auto;
}

.tiptap.ProseMirror:focus-visible {
    outline: 1px solid var(--custom-primary);
}

.tiptap :first-child {
    margin-top: 0;
}

/* List styles */
.tiptap ul,
.tiptap ol {
    padding: 0 1rem;
    margin: 1.25rem 1rem 1.25rem 0.4rem;
}

.tiptap ul li p,
.tiptap ol li p {
    margin-top: 0.25em;
    margin-bottom: 0.25em;
}

/* Heading styles */
.tiptap h1,
.tiptap h2,
.tiptap h3,
.tiptap h4,
.tiptap h5,
.tiptap h6 {
    line-height: 1.1;
    margin-top: 2.5rem;
    text-wrap: pretty;
}

.tiptap h1,
.tiptap h2 {
    margin-top: 3.5rem;
    margin-bottom: 1.5rem;
}

.tiptap h1 {
    font-size: 1.4rem;
}

.tiptap h2 {
    font-size: 1.2rem;
}

.tiptap h3 {
    font-size: 1.1rem;
}

.tiptap h4,
.tiptap h5,
.tiptap h6 {
    font-size: 1rem;
}

/* Code and preformatted text styles */
.tiptap code {
    background-color: var(--purple-light);
    border-radius: 0.4rem;
    color: var(--black);
    font-size: 0.85rem;
    padding: 0.25em 0.3em;
}

.tiptap pre {
    background: var(--black);
    border-radius: 0.5rem;
    color: var(--white);
    font-family: 'JetBrainsMono', monospace;
    margin: 1.5rem 0;
    padding: 0.75rem 1rem;
}

.tiptap pre code {
    background: none;
    color: inherit;
    font-size: 0.8rem;
    padding: 0;
}

.tiptap blockquote {
    border-left: 3px solid var(--gray-3);
    margin: 1.5rem 0;
    padding-left: 1rem;
}

.tiptap hr {
    border: none;
    border-top: 1px solid var(--gray-2);
    margin: 2rem 0;
}

.tiptap p.is-editor-empty:first-child::before {
    color: #94a3b8;
    font-size: 14px;
    font-weight: 400;
    line-height: 160%;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
}