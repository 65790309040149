.balance-card {
  margin-top: 20px !important;
}
.months-tab {
  color: #272d37;
  padding: 8px 12px;
}
.active-month {
  background-color: var(--custom-selago);
  color: var(--custom-primary);
  border-radius: 6px;
}

