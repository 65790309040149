.work-order__tabs .nav-link.active,
.work-order__tabs .nav-link:hover {
  border-color: transparent;
}

.work-order__tabs .nav-link.active {
  color: var(--custom-grey-900);
  font-size: 13px;
  font-weight: 600;
  border-bottom: 4px solid var(--custom-primary);
}

.work-order__tabs .nav-link {
  margin-bottom: 0;
  border: none;
  color: var(--custom-grey-500);
  font-size: 13px;
  font-weight: 500;
  padding-bottom: 16px;
}

.work-order__tabs .nav-link .badge {
  background-color: var(--custom-grey-100) !important;
  color: var(--custom-grey-700) !important;
}

.work-order__tabs .nav-link:focus-visible {
  box-shadow: none;
}

.layout_active {
  background: var(--custom-selago);
  border: 1px solid transparent;
}

.layout_inactive {
  border: 1px solid var(--custom-catskill-white);
}

.layout_wrapper {
  border-radius: 8px;
  padding: 8px 16px;
  color: var(--custom-primary);
  cursor: pointer;

  /* nilesh add */
  height: 42px;
  width: 50px;
}

.job-status {
  padding: 4px 16px;
  border-radius: 8px;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.2px;
  line-height: 160%;
}

.btn__accept-invite {
  background-color: #f9f1ff;
  border-radius: 12px;
}

.card-tag__fixed {
  background-color: var(--custom-grey-200);
  color: var(--custom-grey-500);
  padding: 2px 6px;
  font-size: 10px;
  font-weight: 500;
}
.nav-tabs {
  border-bottom: var(--bs-nav-tabs-border-width) solid #f3f3f3 !important;
}

.semi-circle {
  position: absolute;
  top: -2px;
  left: 4px;
  height: 90%;
  width: 90%;
  background-color: #0FBCF9;
  border-radius: 50%;
}