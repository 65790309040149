.not-found__card-wrapper {
    height: calc(100vh - 250px);
    scrollbar-width: none;
}

.not-found__card {
    border: 0.5px solid #EAEBF0;
    border-radius: 12px;
}

.not-found__subtitle {
    max-width: 300px;
}