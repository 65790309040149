table th:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

table th:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

thead {
    border-color: transparent;
}

th {
    background-color: var(--custom-grayscale-50) !important;
    padding: 16px !important;
    color: var(--custom-grayscale-600) !important;
    font-size: 12px;
    font-weight: 700;
    line-height: 160%;
    letter-spacing: 0.2px;
}

tbody tr {
    vertical-align: middle;
    border-color: var(--custom-grayscale-200);
}

td {
    padding: 16px !important;
    color: var(--custom-grayscale-900);
    font-size: 12px;
    font-weight: 400;
    line-height: 160%;
}

.page-item>.page-link {
    border-radius: 8px;
    color: var(--custom-grayscale-900);
}

.page-link:focus {
    box-shadow: none;
}

.page-item:first-child>.page-link,
.page-item:last-child>.page-link {
    border-color: var(--custom-grayscale-200);
    background-color: transparent;
    padding: 3px 12px;
}

.page-item__index .page-link {
    border: transparent;
    padding-inline: 14px;
    font-size: 14px;
    font-weight: 600;
}

.page-item:first-child {
    margin-right: 16px;
}

.page-item:last-child {
    margin-left: 16px;
}

.active>.page-link {
    background-color: #F8F8F8;
}

.disabled>.page-link {
    color: var(--custom-grayscale-500);
}

.table-select {
    padding: 10px;
    border-radius: 8px;
    border-color: var(--custom-grayscale-200);
    color: var(--custom-grayscale-900);
    font-size: 12px;
    font-weight: 500;
}

.table-select:focus {
    outline: none;
}

.badge-status__active,
.badge-status__inactive,
.badge-status__away,
.badge-status {
    padding: 6px 12px;
}

.badge-status__active {
    background-color: var(--custom-primary-100) !important;
    color: var(--custom-success-alert);
}

.badge-status__away {
    background-color: var(--custom-primary-alert-50) !important;
    color: var(--custom-primary);
}

.badge-status__inactive {
    background-color: var(--custom-primary-50) !important;
    color: var(--custom-primary-500);
}

.badge-status__assigned {
    background-color: var(--custom-warning-50) !important;
    color: var(--custom-warning-500);
}

.badge-status__in-progress {
    background-color: var(--custom-secondary-50) !important;
    color: var(--custom-secondary-500);
}

.badge-status__active_text {
    color: var(--custom-success-alert) !important;
    border-radius: 10px !important;
    width: fit-content;
}

.badge-status__active_text {
    color: var(--custom-primary) !important;
    border-radius: 10px !important;
    width: fit-content;
}

.badge.text-bg-secondary {
    background-color: var(--custom-grey-200) !important;
    color: var(--custom-grey-500) !important;
}