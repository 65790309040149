.typeOfOccupation {
  font-size: 12px;
  line-height: 160%;
  font-weight: 600;
  color: var(--custom-grayscale-900);
}
.file-downloader {
  font-size: 12px;
  line-height: 160%;
  font-weight: 600;
  color: var(--custom-primary);
}
.table-name {
  font-size: 16px;
  line-height: 160%;
  font-weight: 600;
  color: var(--custom-gray-700);
}
.last-used {
  font-size: 12px;
  line-height: 160%;
  font-weight: 600;
  color: var(--custom-grayscale-900);
}
.integration-card img {
  height: 40px;
  width: 40px;
}
.integration-card h6 {
  font-size: 18px;
  line-height: 160%;
  font-weight: 500;
  color: black;
}
.integration-card p {
  font-size: 14px;
  line-height: 160%;
  color: black;
}
