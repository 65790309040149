.wrapper__right {
  height: calc(100vh - 2rem);
  overflow-y: scroll;
  align-items: center;
  justify-content: center;
}

.hero-card {
  border-radius: 30px;
  background-color: #4f0086;
  background-image: url(../../../../assets/images/overlay.png);
  background-blend-mode: overlay;
  background-size: cover;
  padding: 30px;
  height: calc(100vh - 2rem);
  overflow-y: scroll;
}

/* .hero-typography {
    max-width: 400px;
} */

.hero-typography h1 {
  font-size: clamp(20px, 40px, 60px);
  font-weight: 600;
  letter-spacing: -1.2px;
  color: #fff;
}

.hero-typography p {
  color: #e4dbdb;
  font-size: clamp(14px, 16px, 18px);
  opacity: 0.7;
}

.dark-card {
  border-radius: 20px;
  background: #3e3838;
  padding: 24px;
  /* max-width: 580px; */
  height: fit-content;
  margin-top: auto;
}

.dark-card > p {
  color: #f0e6e6;
  font-size: clamp(12px, 14px, 16px);
}

.card-image {
  height: 40px;
  width: 40px;
  object-fit: cover;
  object-position: top;
  border: 1px solid white;
  border-radius: 20px;
}

.card-title {
  color: white;
  font-size: 14px;
  font-weight: 600;
}

.auth_card-description {
  color: #a29999;
  font-size: 12px;
  margin-bottom: 0;
}

.workrobin-card-description {
  color: #a29999;
  font-size: 12px;
  margin-bottom: 0;
}
.get-started {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
}

.welcome-heading {
  color: black;
  font-size: clamp(28px, 32px, 34px);
  font-weight: 600;
}

.button {
  padding-block: 16px;
  padding-inline: 24px;
  border-radius: 12px;
  width: 100%;
  margin-bottom: 16px;
}

.button__google {
  border: 1px solid #e3effc;
  background: #f9fafb;
}

.google-login-button [role="button"] {
  border-radius: 12px;
  padding: 24px;
  border: 1px solid #e3effc;
  background: #f9fafb;
}

.button__apple {
  border: 1px solid #667185;
  background: transparent;
}

.button span {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.2px;
  margin-left: 12px;
}

.or-register-with-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  margin-bottom: 16px;
}

.or-text {
  color: #000;
  font-size: 14px;
  font-weight: 500;
}



.right-line {
  margin-left: 10px;
}

.login-text {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}

.user-profile-card {
  border-radius: 16px;
  background: #f2f4f7;
  padding: 4px 10px 4px 12px;
  width: fit-content;
  margin-inline: auto;
}

.user-profile-card span {
  color: #344054;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.checkbox-label {
  font-size: clamp(12px, 13px, 14px);
  font-weight: 400;
}
