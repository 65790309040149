.created-pools__plus-wrapper {
    border-radius: 4px;
    border: 1px solid var(--custom-neutrals-200);
    background: #fff;
    padding: 6px 10px;
}

.created-pools__plus-wrapper svg {
    margin-bottom: 2px;
}

.my-provider__active-tab {
    position: relative;
    background-color: transparent;
}
.my-provider__active-tab span {
    color: var(--custom-grey-600);
}
.active.my-provider__active-tab::before {
    content: '';
    position: absolute;
    top: 12px;
    right: 0;
    width: 2px;
    background-color: var(--custom-primary);
    height: 32px;
}
.my-provider__active-tab.active {
    background-color: white;
}
.my-provider__active-tab.active span {
    color: var(--custom-primary);
    font-weight: 600;
}