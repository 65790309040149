.otp-input__wrapper input {
    border: 1px solid var(--custom-catskill-white);
    border-radius: 10px;
    width: 54px !important;
    height: 54px;
    font-size: 14px;
    font-weight: 500;
    color: #0F172A;
    outline: none;
    text-transform: uppercase;
}

.otp-input__wrapper input::placeholder {
    color: #0F172A;
}