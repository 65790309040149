.basic-info__wrapper > .title {
    color: var(--custom-neutrals-100);
}
.basic-info__wrapper > .subtitle {
    color: var(--custom-neutrals-60);
}

.instructions-checkbox > label {
    font-size: 14px;
    font-weight: 500;
    /* color: var(--custom-neutrals-60); */
}