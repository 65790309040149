.history {
  font-size: 24px;
  color: var(--custom-grayscale-900);
  line-height: 130%;
  font-weight: bold;
}
.history-data {
  font-size: 12px;
  line-height: 160%;
  color: var(--custom-grayscale-900);
}
