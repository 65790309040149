.new-wo__stepper {
    border-radius: 16px;
    border: 1px solid var(--custom-selago);
    padding: 14px 12px;
    margin: 1rem 0;
}

.icon__wrapper {
    height: 48px;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.background-primary {
    background-color: var(--custom-primary);
}

.background-titanWhite {
    background-color: #F2F2FF;
}

.color-grey-400 {
    color: var(--custom-grey-400);
}
.filter-wo__checkbox label {
    color: var(--custom-grey-500);
}