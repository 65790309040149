.applied-provider {
  background-color: var(--custom-grayscale-100);
  border-radius: 16px;
  padding: 2px 8px;
}
.menuWrapper {
  background-color: var(--custom-gray-100);
  border-radius: 100%;
  height: 24px;
  width: 24px;
}
.message-sender {
  background-color: var(--custom-secondary-200) !important;
  height: 40px !important;
  width: 40px !important;
}
.message-receiver {
  background-color: #f3e1ff !important;
  height: 40px !important;
  width: 40px !important;
  color: var(--custom-primary) !important;
  font-weight: bold !important;
}
.complete-work__view-btn,
.complete-work__view-btn.btn:hover {
  color: var(--custom-gray-700);
  border-color: var(--custom-gray-300);
}

.message_items {
  background-color: #eaecee;
  padding: 7px 15px;
  border-radius: 8px;
  min-width: 200px;
  max-width: 400px;
  overflow-wrap: anywhere;
}

.message_items_provider {
  background-color: var(--custom-primary);
  padding: 7px 15px;
  color: white;
  min-width: 200px;
  max-width: 400px;
  border-radius: 8px;
  overflow-wrap: anywhere;
}

.message_body_main {
  height: 460px;
  overflow: hidden;
  padding-bottom: 10px;
}

.message_body_bg {
  max-height: 35vh;
  overflow-y: scroll;
  margin-bottom: 10px;
  overflow-x: hidden;
  scrollbar-color: #cbd5e1 transparent;
  scrollbar-width: thin;
}

.message_type_control {
  position: absolute;
  bottom: 0px;
  background-color: white;
  padding: 10px 0px;
  width: 75%;
}

.provider_chat_body {
  height: 300px;
  overflow-x: hidden;
  scrollbar-color: #cbd5e1 transparent; /* Thumb color | Track color */
  scrollbar-width: thin;
  overflow-y: scroll;
}
