.card-description {
    height: 45px; 
    overflow: hidden; 
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; 
    -webkit-box-orient: vertical;
  }
  .payment-card-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    height: 100%; 
  }
  .color-neutrals-80{
    color: var(--custom-neutrals-80);
  }
  .color-bg-light-gray {
    background-color: #F8F8FD !important;
  }

  .border-primary {
    border-color: var(--custom-primary) !important;
  }