.progressBar {
    height: 8px;
    background-color: var(--custom-grey-200);
    min-width: 170px;
}

.border-gray-100 {
    border-color: var(--custom-gray-100);
}

.border-secondary {
    border-color: #94A3B8;
}

.itemBaseWrapper {
    border-radius: 12px;
    border: 1px solid var(--custom-grey-200);
    padding: 16px;
}