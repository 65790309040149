.deliverables-card h6 {
  font-size: 16px;
  line-height: 160%;
  color: black;
  font-weight: 500;
}
.list-items__deliverables li {
  font-size: 16px;
  line-height: 160%;
  color: var(--custom-grey-600);
  width: 517px;
}

