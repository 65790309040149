.badge__group {
    padding: 12px 16px;
    border: 1px solid #E5BFFF;
    border-radius: 4px;
    background-color: var(--custom-brand-50);
    color: var(--custom-brand-700);
}

.badge__group .progress {
    min-width: 265px;
    height: 7px;
    background-color: var(--custom-lights-border);
}

.badge__group .progress .progress-bar, .profile-completion-text ~ .progress .progress-bar {
    border-radius: 100px;
}

.badge__group .progress .progress-bar {
    background-color: var(--custom-primary);
}

.profile-completion-text ~ .progress .progress-bar {
    background-color: var(--custom-brand-600);
}

.approve_wo-card {
    background: #FFF5F5;
    border: 1px solid #d42620;
    padding: 12px 16px;
}

.badge__group button, .approve_wo-card button {
    background-color: var(--custom-brand-600);
    border-radius: 4px;
}

.top-nav {
    box-shadow: 0px -1px 0px 0px #D6DDEB inset;
}

.top-nav h4 {
    font-size: 24px;
    font-weight: 700;
    line-height: 120%;
    color: var(--custom-neutrals-100);
}

.greeting__filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.greeting__filter p {
    margin-bottom: 0;
    color: var(--custom-neutrals-60);
    font-weight: 500;
    line-height: 160%;
}

.w-150px {
    width: 150px;
}