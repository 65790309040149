.switch-enable {
  font-size: 22px;
}
.switch-enable.form-switch .form-check-input:checked {
  background-color: var(--custom-success-alert) !important;
}
.form-switch .form-check-input:focus {
  background-color: white;
}
.decimal-order {
  list-style: decimal;
  padding-left: 14px;
}
.add-payment {
  border-style: dashed;
  border-radius: 12px;
  /* border: 1px solid var(--custom-grey-400); */
  border-color: var(--custom-grey-400);
  /* height: 175px; */
  /* width: 255px; */
}
.header-color {
  color: #2e2c34;
}
.default-badge {
  border-radius: 16px;
  background-color: var(--custom-gray-100);
  padding: 2px 16px;
  color: var(--custom-gray-700);
  line-height: 18px;
  font-weight: 500;
}
.set-as-default-badge {
  border-radius: 16px;
  border: 1px solid var(--custom-gray-700);
  padding: 2px 16px;
  color: var(--custom-gray-700);
  line-height: 18px;
  font-weight: 500;
}
.edit-btn {
  border: 1px solid rgb(204, 204, 245);
  /* padding-block: 14px; */
  border-radius: 24px;
  padding: 4px 16px;
}
.cancel-btn {
  border: 1px solid #fcb59a;
  /* padding-block: 14px; */
  border-radius: 24px;
  padding: 4px 16px;
  color: #cc400c;
}
.upload-resume {
  border-radius: 8px;
  background-color: var(--custom-gray-100);
  padding: 24px 42px;
}
.remove-selected-resume {
  background-color: var(--custom-primary-50);
  color: var(--custom-error-color);
  border-radius: 8px;
  padding: 12px 18px;
}
.uploaded-file-name {
  color: var(--custom-dark-shade-purple);
  max-width: 350px;
}
.upload-resume-btn {
  background-color: var(--custom-gray-100);
  color: var(--custom-grey-600);
}

.working-hours__description {
  max-width: 364px;
}
.btn__pause-account {
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  background: #FC8800;
  color: white;
}
.min-w-20 {
  min-width: 20% !important;
}
.color-brand-primary {
  color: #4640DE;
}
.checkr-image {
  height: 21px;
}
label[for="legalNameZipCode"].form-check-label {
  font-size: 14px;
  font-weight: 500;
  color: var(--custom-dark-gray);
}