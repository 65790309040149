#sidebar-wrapper {
  height: 100vh;
  /* min-height: 100vh; */
  width: 17rem;
  overflow-y: auto;
  margin-left: -17rem;
  padding-inline: 16px;
  padding-top: 10px;
  transition: margin 0.25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 10px;
  margin-bottom: 1rem;
}

/* #sidebar-wrapper .list-group {
    width: 15rem;
} */

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }
}

.list-group__item-active {
  border-radius: 4px;
  background: var(--custom-selago) !important;
}

.list-group__item:not(:last-child) {
  margin-bottom: 1rem;
}

.list-group__item span {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}
