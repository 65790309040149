.tab-bottom {
  border-bottom: 1px solid #dee2e67a;
  /* padding: 0px 0px 8px 0px; */
}
.custom-active-tab {
  color: var(--custom-grey-900) !important;
  font-size: 13px;
  font-weight: 500;
  border-bottom: 4px solid var(--custom-primary);
  padding: 0px 12px 10px 12px;
  margin: 0px 20px 0px 0px;
}
.custom-tab-style {
  color: var(--custom-grey-500);
  font-size: 13px;
  font-weight: 500;
  padding: 0px 12px 10px 12px;
  margin: 0px 20px 0px 0px;
}
