.current-offer div:nth-child(1) {
  font-size: 12px;
  color: var(--custom-primary);
  line-height: 160%;
  font-weight: 800;
}
.current-offer div:nth-child(2) {
  font-size: 12px;
  color: var(--custom-primary);
  line-height: 160%;
  background-color: #F9F1FF;
  border-radius: 16px;
  padding: 2px 8px;
  width: fit-content;
  font-weight: 500;
}
.counter-offer div:nth-child(1) {
  font-size: 12px;
  color: var(--custom-grayscale-900);
  line-height: 160%;
  font-weight: 800;
}
.counter-offer div:nth-child(2) {
  font-size: 12px;
  color: var(--custom-grayscale-900);
  line-height: 160%;
  background-color: var(--custom-gray-100);
  border-radius: 16px;
  padding: 2px 8px;
  width: fit-content;
  font-weight: 500;
}
