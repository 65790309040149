.field-icon {
  background-color: var(--custom-selago);
  border-radius: 100%;
  height: 30px;
  width: 30px;
  color: var(--custom-primary);
}
.field-name {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: var(--custom-gray-500);
}
