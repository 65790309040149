.filterButton {
    border-radius: 8;
    border: "1px solid #DAE0E6";
    background-color: "#EFEFF1";
    padding-inline: 10px;
}

.topHead {
    color: var(--custom-neutrals-100);
    line-height: 120%;
}

.in-progress__offcanvas-wrapper {
    height: calc(100vh - 155px);
    overflow: auto;
}

.published-content__card-skills {
    height: 100%;
    max-height: 24px;
    min-height: 24px;
}

.templates-content__card-desc {
    height: 100%;
    max-height: 36px;
    min-height: 36px;
}