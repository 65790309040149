.enable-security {
  font-size: 16px;
  line-height: 160%;
  color: var(--custom-primary);
}
.policy-instruction {
  font-size: 16px;
  line-height: 160%;
  color: black;
}
.small-header {
  font-size: 16px;
  line-height: 160%;
  color: black;
  font-weight: 500;
}
ul li {
  font-size: 16px;
  line-height: 160%;
  color: black;
}
.login-restriction-policy {
  font-size: 14px;
  line-height: 20px;
  color: #84818a;
  font-weight: 500;
}
.primary-text-color {
  font-size: 16px;
  line-height: 160%;
  color: var(--custom-primary);
}
.NumberIndex ul {
  list-style: decimal;
  width: 100%;
}
.NumberIndex {
  width: 8%;
}
.QR-number {
  font-size: 16px;
  font-weight: bold;
  line-height: 160%;
  color: var(--custom-neutrals-100);
}
.enabled-authentication p:nth-child(1) {
  font-size: 18px;
  line-height: 160%;
  color: var(--custom-primary);
}
.enabled-authentication p:nth-child(2) {
  font-size: 18px;
  line-height: 160%;
  color: black;
}
.defaultChecked {
  opacity: 0.5;
}
.notifications-table th {
  border-bottom: 1px solid var(--custom-grey-200);
  background-color: transparent !important;
}
.notifications-table td {
  font-size: 14px;
  line-height: 160%;
  font-weight: 500;
  color: var(--custom-grey-600);
}

.table-heading {
  font-size: 16px;
  line-height: 160%;
  font-weight: 600;
  color: var(--custom-grey-700);
}
/* .form-check-input {
  height: 16px !important;
  width: 16px !important;
} */
.general-policy{
  color: var(--custom-grey-600);
  line-height: 20px;
  font-size: 14px;
  font-weight: 500;
}