@font-face {
  font-family: "Inter";
  src: url(/public/fonts/Inter/Inter-VariableFont_opsz,wght.ttf) format("truetype");
}

body {
  font-family: "Inter", serif !important;
}

.mt-200 {
  margin-top: 200px;
}

p {
  margin-bottom: 0 !important;
}

button:disabled {
  cursor: not-allowed;
}

.form-check-input:not(:checked) {
  border-color: #98a2b3 !important;
}

.form-check-input:focus {
  box-shadow: none !important;
  border-color: #98a2b3 !important;
}

.provider-checkbox>.form-check-input:checked {
  border-color: var(--custom-primary) !important;
  background-color: var(--custom-primary) !important;
}

.buyer-checkbox>.form-check-input:checked {
  border-color: var(--custom-brand-600) !important;
  background-color: var(--custom-brand-50) !important;
}

.buyer-checkbox>.form-check-input:checked[type="checkbox"] {
  --bs-form-check-bg-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9ImNoZWNrIj4KPHBhdGggaWQ9Ikljb24iIGQ9Ik0xMS42NjU0IDMuNUw1LjI0ODcgOS45MTY2N0wyLjMzMjAzIDciIHN0cm9rZT0iIzdGNTZEOSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9nPgo8L3N2Zz4K") !important;
}

.form-check-input:checked+label {
  color: var(--custom-neutrals-100);
}

.form-check-input:checked[type="radio"] {
  background-color: transparent !important;
  border-color: var(--custom-primary) !important;
}

.form-check-input:checked[type="radio"] {
  --bs-form-check-bg-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9Jy00IC00IDggOCc+PGNpcmNsZSByPScyJyBmaWxsPScjOTIwQUYyJy8+PC9zdmc+") !important;
}

/* Chrome, Safari, Edge, Opera */
.hide__arrows input::-webkit-outer-spin-button,
.hide__arrows input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.hide__arrows input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

@media (min-width: 768px) {
  .profile-wrapper .col-md-4 {
    width: 37% !important;
  }
}

.scrollbar-thin {
  scrollbar-width: thin !important;
}

.scrollbar-none {
  scrollbar-width: none !important;
}

.card-wrapper {
  border-radius: 12px !important;
  border: 1px solid var(--custom-catskill-white) !important;
}

@media (min-width: 576px) {
  .modal-sm.invite-dialog {
    --bs-modal-width: 350px;
  }
}

.offcanvas {
  border-radius: 12px;
  border: 0.5px solid #eaebf0;
}

.offcanvas-title~.pdf-close__wrapper {
  color: #1d2739 !important;
  background: var(--custom-grayscale-200) !important;
}

.loader-button {
  width: 17px;
  height: 17px;
  border: 1px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite !important;
  /* margin-top: 12px !important; */
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spin-do {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Date Picker */
.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__day:not([aria-disabled="true"]):hover {
  border-radius: 1rem !important;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
  background-color: rgba(145, 10, 242, 0.5) !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: #d195f2 !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-range,
.react-datepicker__day--in-selecting-range:not([aria-disabled="true"]):hover,
.react-datepicker__day--keyboard-selected:not([aria-disabled="true"]):hover {
  background-color: var(--custom-primary) !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 100px !important;
}

.text-justify {
  text-align: justify !important;
}

/* Line Clamp */

.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.pac-container {
  z-index: 9999 !important;
}

.left-line,
.right-line {
  flex-grow: 1;
  border: 0;
  border-top: 1px solid var(--custom-catskill-white);
}

.left-line {
  margin-right: 10px;
}

.pointer {
  cursor: pointer !important;
}

.sigCanvas {
  cursor: cell;
  border: 1px solid rgb(200, 200, 200);
  border-radius: 5px;
}