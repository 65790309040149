.download-document {
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid #dae0e6;
  background-color: transparent;
}
.download-document span {
    font-size: 14px;
    line-height: 20px;
    color: #272d37;
  }
