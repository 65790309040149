.wideButton{
    background-color: var(--custom-selago);
    color: var(--custom-grey-800);
    border-radius: 6px;
    padding: 10px 54px;
    border: none;
    width: 100%;
    font-size: 12px;
    line-height: 160%;
    font-weight: 600;
  }