.desc {
    height: 42px;
}

.map-image__wrapper {
    height: calc(100% - 3rem);
}

.mb-2px {
    margin-bottom: 2.5px !important;
}