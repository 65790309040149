.payment-table__wrapper {
    background-color: #F8F8FD;
    padding: 20px 20px 4px;
}

.week-day__wrapper {
    border-radius: 8px;
    background-color: white;
    padding: 10px 14px;
    cursor: pointer;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.checked {
    border: 1px solid var(--custom-primary);
    color: var(--custom-primary);
}

.unchecked {
    border: 1px solid var(--custom-gray-300);
    color: var(--custom-gray-500);
}