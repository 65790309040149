.dropzone__wrapper {
    border-radius: 8px;
    border: 2px dashed #4640DE;
    background: #F8F8FD;
    padding: 24px 42px;
}

.pdf-title {
    color: #150A33;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
}

.pdf-size {
    color: #B5B6B7;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
}

.pdf-close__wrapper {
    padding: 6px 12px;
    border-radius: 6px;
    background: #FFECE5;
    color: #D42620;
    cursor: pointer;
}

.skills-list {
    position: absolute;
    top: 90px;
    width: 100%;
    height: 150px;
    overflow-y: auto;
    scrollbar-width: none;
    padding: 16px;
    border-radius: 12px;
    background: #FFF;
    margin-bottom: 0;
    box-shadow: 5px 5px 50px 0px rgba(26, 32, 44, 0.06);
}

.skills-list li {
    margin-bottom: 16px;
    color: #667185;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
}

.profile_label {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    line-height: 160%
}

.profile_timezone {
    color: #667185;
    font-size: 12px;
    font-weight: 400;
    line-height: 160%;
}

.skill {
    background-color: #F8F8FD;
    padding: 4px 12px;

    color: var(--custom-primary);
    font-size: 14px;
    font-weight: 400;
    line-height: 160%;
}

.btn-delete {
    background-color: #D42620;
    border-radius: 50%;
    border: none;
    padding: 8px 12px;
}

.profileImage-size {
    height: 96px;
    width: 96px;
}

.border-grey-100 {
    border-color: var(--custom-grey-100);
}