.my-work-search-input {
  background-color: transparent !important;
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 400px;
}

.applied-content__card {
  height: 100%;
  max-height: 19px;
  min-height: 19px;
}