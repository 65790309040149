.additional-information h6 {
  font-size: 16px;
  line-height: 160%;
  color: black;
  font-weight: 500;
}
.status {
  background-color: var(--custom-grey-100);
  padding: 2px 10px;
  font-size: 14px;
  line-height: 20px;
  color: var(--custom-grey-700);
  border-radius: 15px;
  width: fit-content;
}
.documentation-icon {
  color: var(--custom-error-alert);
  font-size: 50px;
}
.documentation-info h6 {
  color: var(--custom-dark-shade-purple);
  max-width: 250px;
}
.documentation-info p {
  font-size: 12px;
  line-height: auto;
  color: var(--custom-grey-400);
}
.view-document {
  color: var(--custom-grey-700);
  font-size: 12px;
  line-height: 20px;
}
.location {
  font-size: 16px;
  line-height: 160%;
  color: var(--custom-grey-600);
}
.status-in-hours {
  background-color: var(--custom-grey-100);
  padding: 2px 8px;
  font-size: 12px;
  line-height: 18px;
  color: var(--custom-grey-700);
  border-radius: 15px;
  width: fit-content;
}
.payment-type {
  font-size: 16px;
  line-height: 160%;
  color: var(--custom-grey-600);
}
.fee-wrapper {
  color: var(--custom-grey-900);
  font-size: 16px;
  line-height: 160%;
  font-weight: 500;
}
.amount-wrapper {
  color: var(--custom-primary);
  font-size: 20px;
  line-height: 160%;
  font-weight: 600;
}
