/* .form-check-input {
  width: 16px;
  height: 16px;
  margin: 0;
} */
.applicant-profile {
  border-radius: 100%;
  height: 24px;
  width: 24px;
}
.applicant-name {
  font-size: 12px;
  line-height: 160%;
  color: var(--custom-grayscale-900);
  font-weight: 500;
}
.about-applicant {
  font-size: 12px;
  line-height: 160%;
  color: var(--custom-grayscale-900);
}
.job-completed {
  font-weight: 600;
}
.applicant-search-input {
  background-color: var(--custom-grayscale-50) !important;
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
  border-radius: var(--bs-border-radius) !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 320px;
}
.smart-finder {
  border-radius: 12px;
  background-color: #f9f1ff;
  padding: 7px 16px;
  color: var(--custom-primary);
  font-weight: bold;
}
.smart-finder.zero_button:disabled > div > svg {
  color: white !important;
}
.smart-finder-icon {
  border-radius: 100%;
  background-color: var(--custom-grayscale-500);
  height: 20px;
  width: 20px;
  color: white;
}
.sort-applicant {
  padding: 5px 14px;
  color: var(--custom-grey-500);
  border-radius: 12px;
  background-color: transparent;
  border-color: var(--custom-grey-200);
}
.job-category {
  background-color: var(--custom-grey-75);
  color: var(--custom-primary);
  border-radius: 16px;
  padding: 4px 12px;
  width: fit-content;
  font-size: 12px;
  line-height: 160%;
  font-weight: 500;
}
.more-category {
  font-size: 10px;
  line-height: 220%;
  color: var(--custom-primary);
}
