.work-details-information-wrapper {
  width: 100%;
  background-color: #fff;
  padding: 24px 16px;
  border-radius: 12px;
  border: 2px solid var(--custom-gray-100);
  height: auto;
}
.badge-status__error {
  background-color: var(--custom-success-color) !important;
  padding: 10px 14px;
  color: white !important;
  font-size: 12px;
  border-radius: 20px !important;
}
.badge-status__available {
  background-color: var(--custom-error-color) !important;
  padding: 10px 14px;
  color: white !important;
  font-size: 12px;
  border-radius: 20px !important;
}
