.client-heading h5 {
  font-size: 24px;
  line-height: 130%;
  font-weight: bold;
  color: var(--custom-grayscale-900);
}
.add-client {
  font-size: 15px;
  line-height: 22px;
  font-weight: 600;
  color: white;
}
.add-icon {
  color: white;
  height: 22px;
  width: 22px;
}
.client-profile img {
  height: 25px;
  width: 25px;
  border-radius: 100%;
}
.client-profile p {
  font-size: 12px;
  line-height: 160%;
  color: var(--custom-grayscale-900);
  font-weight: 500;
}
.client-data {
  font-size: 12px;
  line-height: 160%;
  color: var(--custom-grayscale-900);
}
.client-heading h4 {
  font-size: 14px;
  line-height: 160%;
  font-weight: 600;
  color: black;
}
.client-heading h3 {
  font-size: 15px;
  line-height: 100%;
  font-weight: bold;
  color: black;
}
.total-revenue-and-expenses p:nth-child(1) {
  font-size: 16px;
  line-height: 160%;
  font-weight: 500;
  color: black;
}
.total-revenue-and-expenses p:nth-child(2) {
  font-size: 20px;
  line-height: 100%;
  font-weight: bold;
  color: black;
}

