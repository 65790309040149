.pending-status {
  background-color: var(--custom-warning-50);
  color: var(--custom-pending-color);
  border-radius: 6px;
  padding: 2px 6px;
  width: fit-content;
}
.all-suggestion-wrapper{
  border: 1px solid var(--custom-catskill-white) !important;
  padding: 16px 26px;
}
.card-like-dislike{
  border-radius: 4px !important;
  border: 1px solid var(--custom-catskill-white) !important;
  padding: 4px 11px;
}
.like-count{
  color: #4B587C;
}