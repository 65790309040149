.add-client-button {
  background-color: var(--custom-primary);
  border: none;
  width: fit-content !important;

}
.cancel-button {
  padding-block: 14px;
  border: 1px solid #ccccf5;
}
.form-heading h6 {
  color: black;
  font-size: 20px;
  font-weight: 600;
}
.form-heading p {
  color: var(--custom-gray-700);
  font-size: 16px;
  line-height: 24px;
}
/* label {
  color: black;
  font-size: 14px;
  line-height: 160%;
  font-weight: 500;
} */
.form-wrapper{
  position: relative;
  height: 80vh;
}
.button-bottom-side {
  position: absolute;
  bottom: -20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
