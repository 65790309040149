#page-content-wrapper {
    min-width: 100vw;
}

@media (min-width: 768px) {
    #page-content-wrapper {
        min-width: 0;
        width: calc(100% - 17rem);
    }
}

#page-content-wrapper > .container-fluid {
    height: calc(100vh - 73px);
    overflow-y: auto;
}

#profile-dropdown~.dropdown-menu.show {
    left: -190px;
    min-width: 240px;
}

#wallet-dropdown~.dropdown-menu.show {
    left: -240px;
    min-width: 280px;
}

.wallet-dropdown__title-wrapper {
    border-radius: 6px;
    background: var(--custom-selago);
    color: var(--custom-grey-600)
}

#notification-dropdown~.dropdown-menu.show {
    left: -280px;
    min-width: 320px;
    padding-bottom: 0;
}

.dropdown-toggle::after {
    display: none !important;
}

.dropdown-menu {
    border-radius: 8px;
    border: 1px solid var(--custom-grey-100);
    background: #FFF;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.dropdown__tag {
    background-color: var(--custom-grey-75) !important;
    color: var(--custom-primary);
    font-size: 12px;
    font-weight: 500;
    line-height: 160%
}

.dropdown__tag.btn:hover, .dropdown__tag.btn:focus, .dropdown__tag.btn:active {
    color: var(--custom-primary);
    border-color: transparent;
}

.vr.align-self-center {
    background-color: var(--custom-grey-500)
}

.form-control__search {
    background-color: #F4F4F4;
    border-radius: 8px;
    border: 2px solid transparent;
    padding-left: 2.25rem;
}

.form-control__search:focus,
.form-control__search:focus-visible {
    box-shadow: none;
    border: 2px solid var(--custom-primary);
    background-color: #FFF;
}

.form-control__search:focus::placeholder {
    opacity: 0;
}

.form-control__search-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%)
}

.message-indicator {
    top: 6px;
    right: -6px;
}
.notification-indicator {
    top: 4px;
    right: -3px;
}