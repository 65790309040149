.form-label {
  color: #000;
  font-size: 14px;
  font-weight: 500;
}

.form-input {
  display: block;
  border-radius: 10px;
  border: 1px solid var(--custom-catskill-white);
  padding: 16px 20px;
  width: 100%;
  color: #0f172a;
  font-size: 14px;
  font-weight: 500;
}

.form-input::placeholder {
  color: #94a3b8;
  font-size: 14px;
  font-weight: 400;
  line-height: 160%;
}

.form-input:focus-visible {
  outline: 1px solid var(--custom-primary);
}

.form-error:focus-visible {
  outline: 1px solid #c00100;
}

.error-text {
  color: #c00100;
  font-size: 14px;
}

.password-toggle-icon {
  position: absolute;
  top: 16px;
  right: 20px;
  cursor: pointer;
}
.input_data_style input[type="date"] {
  color: #a8adb7;
}
.info-text {
  color: var(--custom-neutrals-60);
  font-size: 14px;
}